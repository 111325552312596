import React from "react";
import "./Affiliates.css";

export const Affiliates = () => {
  return (
    <>
      <div className="container-fluid afContainerEarn">
        <div className="afContainerEarnBg">
          <img src="/images/afBg.png" alt="PowerTrade" />
        </div>
        <div className="afContainerEarnBg02"></div>

        <div className="afHeader">
          <span className="afSubtitle">AFFILIATE PROGRAM</span>
          <h1 className="afTitle">Earn like a&nbsp;boss</h1>
          <p className="afHelper">
            Permanently earn up to <em>50% commissions</em> on all trading fees
            collected in your network
          </p>
          <a
            href="https://powertrade.typeform.com/to/W2uZZO9l"
            className="afBtnJoinNow"
            target="_blank"
          >
            Join Now
          </a>
        </div>
      </div>

      <div className="container afContainerStep">
        <div className="row afListStep">
          <div className="col-md">
            <div className="afListItem">
              <img className="afItemIcon" src="/images/af01.png" />
              <span className="afItemHelper">Step1.</span>
              <h6 className="afItemTitle">Create</h6>
              <p className="afItemMessage">
                After you have applied to the program and are approved, you’ll
                get a unique referral link to share with your audience.
              </p>
            </div>
          </div>
          <div className="col-md">
            <div className="afListItem">
              <img className="afItemIcon" src="/images/af02.png" />
              <span className="afItemHelper">Step2.</span>
              <h6 className="afItemTitle">Promote</h6>
              <p className="afItemMessage">
                Promote your custom link in articles, social media, and other
                forms of content, and earn commissions on every new active
                account.
              </p>
            </div>
          </div>
          <div className="col-md">
            <div className="afListItem">
              <img className="afItemIcon" src="/images/af03.png" />
              <span className="afItemHelper">Step3.</span>
              <h6 className="afItemTitle">Earn</h6>
              <p className="afItemMessage">
                Earn up to <em>50% commissions</em> when users create an account
                with your affiliate link, you’ll receive commission on every
                trade they make.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="afBenefit">
          <h3 className="afBenefitTitle">Program Benefits</h3>
          <p className="afBenefitHelper">
            Check more detailed rules and benefits of the affiliate program{" "}
            <a
              href="https://support.power.trade/hc/en-us/articles/4422252388749"
              target="_blank"
            >
              Detailed&nbsp;Rules
            </a>
          </p>
          <div className="afBenefitDiv"></div>
          <h6 className="afMinTitle">Minimum requirements</h6>
          <p className="afMinBody">
            Must have more than 5000 followers on social media or more than 500
            members in a trading community. Only eligible users can participate
            after submitting an&nbsp;application
          </p>
        </div>
      </div>

      <div className="container-fluid afContainerCTA">
        <div className="afCTA">
          <h2 className="afCTATitle">Earn with PowerTrade</h2>
          <p className="afCTAMessage">
            Join PowerTrade’s affiliate program and start earning&nbsp;now
          </p>
          <div className="afCTABtnWrp">
            <a
              href="https://powertrade.typeform.com/to/W2uZZO9l"
              className="afBtnJoinNow"
              target="_blank"
            >
              Join Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
