import * as React from "react";
import type { HeadFC } from "gatsby";
import { Affiliates } from "../containers/affiliates/Affiliates";
import { Layout } from "../components/Layout";

const InstitutionalPage = () => {
  return (
    <Layout>
      <Affiliates />
    </Layout>
  );
};

export default InstitutionalPage;

export const Head: HeadFC = () => <title>Affiliates</title>;
